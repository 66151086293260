import './App.css';

import {useState, setState} from 'react'

import {Container, Button, Form} from 'react-bootstrap';

import Amplify from "aws-amplify";
import { API } from 'aws-amplify';

import awsExports from "./aws-exports";

Amplify.configure(awsExports);

const formState = { alias: '' };

function updateFormState(key, value) {
  formState[key] = value;
}

function App() {

  async function getAccount() {
    const data = {
      body: {
        alias: formState.alias
      }
    };
  
    await API.post('accountVendor', '/account', data).then( res => setState(res.data) );
  }

  const [state, setState] = useState([])
  return (
    <Container>
      <div>
        <h1>DeepComposer Account Vendor</h1>
        <h3>Request an account</h3>
        <br/>
        <Form>
          <Form.Group>
            <Form.Control placeholder="Alias (without the @)" onChange={e => updateFormState('alias', e.target.value) } />
            <Button onClick={getAccount}>Submit</Button>
          </Form.Group>
        </Form>
        <ul style={{borderStyle: "solid"}}>
        {state.map( d => <li>{d}</li>)}
        </ul>
        <p>
          <h4>Event Support Details:</h4>
          Console URL: <a href = "https://console.aws.amazon.com/console/home" target="_blank" className="Console">https://console.aws.amazon.com/console/home</a><br />
          Sign In Option: <span className="UserOption">As IAM User</span><br />
          Region: <span className="Region">us-east-1</span> <br /> <br />
          If you have any issues accessing your account, please reach out to me on <a href="http://amzn-aws.slack.com/team/W017VMK3LNM" target="_blank">Slack</a>, <a href="https://app.chime.aws/conversations/new?email=dabounds@amazon.com" target="_blank">Chime</a>, or <a href="mailto:dabounds@amazon.com" target="_blank">email</a>.<br />  
          You can also reach the <a href="https://amzn-aws.slack.com/archives/C022B1GUZE2" target="_blank">#deepcomposer-event-support</a> channel on Slack.<br />
          Happy <a href="https://aws.amazon.com/deepcomposer/" target="_blank">composing</a> and good luck!
        </p>
        <img src = "./deepcomposer.png" alt="AWS Deepcomposer Keyboard Interface" />
      </div>
    </Container>
  );
}

export default App;